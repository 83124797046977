<template>
  <div class="home">
    <b-jumbotron class="text-center home-hero" :lead="$t('general.slogan')">
      <template #header><img class="img-fluid" src="@/assets/nl/checkda.svg"></template>
    </b-jumbotron>
    <b-row>
      <b-col sm class="mb-4">
        <b-card class="text-center">
          <h3>{{ $t('home.student.title') }}</h3>
          <p>{{ $t('home.student.text') }}</p>
          <b-button :to="{ name: 'Students' }">{{ $t('home.button') }}</b-button>
        </b-card>
      </b-col>
      <b-col sm class="mb-4">
        <b-card class="text-center">
        <h3>{{ $t('home.school.title') }}</h3>
        <p>{{ $t('home.school.text') }}</p>
        <b-button :to="{ name: 'Schools' }">{{ $t('home.button') }}</b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    name: 'Home',
  }
</script>

<style>
.home-hero {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
</style>
