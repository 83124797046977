import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import './app.scss'
import router from './router'
import i18n from './i18n'

//import { library } from '@fortawesome/fontawesome-svg-core'
//import { faAngleDoubleDown } from '@fortawesome/pro-solid-svg-icons'
//import { faGooglePlay, faAppStore } from '@fortawesome/free-brands-svg-icons'
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

//library.add( faAngleDoubleDown, faGooglePlay, faAppStore )

//Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)

Vue.config.productionTip = false

export const app = createApp(App)

app.use(i18n)
app.use(router)
app.mount("#app");