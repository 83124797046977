export default {
  "general": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkda"])},
    "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My school in my pocket!"])}
  },
  "home": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
    "student": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For students and parents"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is Checkda and how does it work?"])}
    },
    "school": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For schools"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See here how Checkda can help at your school."])}
    }
  },
  "apps": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can download our student companion app from the links below."])},
    "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android"])},
    "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iOS"])},
    "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming soon!"])}
  },
  "students": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students and parents"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkda is a system for schools to simplifiy Attendance and Meal registration."])},
    "attendance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance"])},
      "imgalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance registration device"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The students check themselves in when they arrive at school by holding their badge over the scanner."])}
    },
    "meals": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meal administration"])},
      "imgalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meal ordering device"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each student gets a badge. With this card the student can order their lunch."])}
    }
  },
  "schools": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schools"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkda helps simplify your administration"])},
    "attendance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance registration"])},
      "imgalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendance registration device"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The students check themselves in when they arrive at school by holding their badge over the scanner."])}
    },
    "meals": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meal administration"])},
      "imgalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meal ordering device"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each student gets a badge. With this card the student can order their lunch."])}
    }
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We only use this to process your inquiry."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message sent successfully"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message not sent"])},
    "form": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address:"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Name:"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message:"])}
    }
  },
  "privacypolicy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy for Checkda"])},
    "part1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of our main priorities is the privacy of our users. This Privacy Policy document contains types of information that is collected and recorded by Checkda and how we use it."])},
    "part2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us."])},
    "part3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Checkda. This policy is not applicable to any information collected offline or via channels other than this website."])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consent"])},
    "part4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By using our website or apps, you consent to our Privacy Policy and agree to its terms."])},
    "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information we collect"])},
    "part5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information."])},
    "part6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide."])},
    "part7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number."])},
    "subtitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How we use your information"])},
    "part8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use the information we collect in various ways, including to:"])},
    "subtitle4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log Files"])},
    "part9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkda follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information."])},
    "subtitle5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR Data Protection Rights"])},
    "part10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:"])},
    "list1": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide, operate, and maintain our website"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improve, personalize, and expand our website"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Understand and analyze how you use our website"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Develop new products, services, features, and functionality"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send you emails"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find and prevent fraud"])}
    },
    "list2": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to erasure – You have the right to request that we erase your personal data, under certain conditions."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions."])}
    },
    "part11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us."])}
  },
  "forms": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset form"])}
  },
  "navbar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "student+parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Students and parents"])},
    "schools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schools"])},
    "apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])}
  },
  "languages": {
    "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NL"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])}
  }
}