<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="primary">
      <b-navbar-brand :to="{ name: 'Home' }">
        <img src="@/assets/nl/checkda.svg" class="logo">
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item :to="{ name: 'Home' }" :class="{active: $route.name==='Home'}" class="checkda-nav-link">{{ $t("navbar.home") }}</b-nav-item>
          <b-nav-item :to="{ name: 'Students' }" :class="{active: $route.name==='Students'}">{{ $t("navbar.student+parents") }}</b-nav-item>
          <b-nav-item :to="{ name: 'Schools' }" :class="{active: $route.name==='Schools'}">{{ $t("navbar.schools") }}</b-nav-item>
          <b-nav-item :to="{ name: 'Apps' }" :class="{active: $route.name==='Apps'}">{{ $t("navbar.apps") }}</b-nav-item>
          <b-nav-item :to="{ name: 'Contact' }" :class="{active: $route.name==='Contact'}">{{ $t("navbar.contact") }}</b-nav-item>
          <b-nav-item href="https://app.checkda.be">{{ $t("navbar.login") }}</b-nav-item>
          <b-nav-item-dropdown :text="this.$t('navbar.language')" right>
            <b-dropdown-item v-for="locale in locales" :key="locale" @click="switchLocale(locale)">{{ $t("languages." + locale)}}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
    }
  },
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
    }
  }
}
</script>

<style scoped>
.logo {
  max-height: 38.48px;
}
</style>

<style>
.nav-item.nav-item.nav-item a {
  color: #132351;
}
</style>
