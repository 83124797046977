export default {
  "general": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkda"])},
    "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijn school op zak!"])}
  },
  "home": {
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lees meer"])},
    "student": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor leerlingen en ouders"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is Checkda en hoe werkt het?"])}
    },
    "school": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor scholen"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk hier hoe Checkda kan helpen op uw school."])}
    }
  },
  "apps": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je kan de studenten app downloaden via de links hieronder."])},
    "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android"])},
    "ios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iOS"])},
    "comingsoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Binnenkort beschikbaar!"])}
  },
  "students": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerlingen en ouders"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkda is een systeem voor scholen om de aanwezigheids- en middagmaaltijdadministratie tevereenvoudigen."])},
    "attendance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheidsregistratie"])},
      "imgalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheidsregistratie toestel"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De leerlingen checken zelf in als ze op school aankomen door met een badge die voor 1 van de lezers gehouden word."])}
    },
    "meals": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middagmaaltijdadministratie"])},
      "imgalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middagmaaltijdadministratie toestel"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De leerling geeft zelf zijn bestelling in voor zijn middageten."])}
    }
  },
  "schools": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scholen"])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkda helps simplify your administration"])},
    "attendance": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheids registratie"])},
      "imgalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanwezigheidsregistratie toestel"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De leerlingen checken zelf in als ze op school aankomen door met een badge die voor 1 van de lezers gehouden word."])}
    },
    "meals": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middagmaaltijd administratie"])},
      "imgalt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middagmaaltijdadministratie toestel"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De leerling geeft zelf zijn bestelling in voor zijn middageten."])}
    }
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We gebruiken dit enkel om uw vraag te behandelen."])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht is verstuurd"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij versturen van bericht"])},
    "form": {
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email adres:"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw naam:"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht:"])}
    }
  },
  "privacypolicy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacybeleid voor Checkda"])},
    "part1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een van onze belangrijkste prioriteiten is de privacy van onze gebruikers. Dit Privacybeleid-document bevat soorten informatie die door Checkda worden verzameld en vastgelegd en hoe we deze gebruiken."])},
    "part2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u nog vragen heeft of meer informatie wenst over ons privacybeleid, aarzel dan niet om contact met ons op te nemen."])},
    "part3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit privacybeleid is alleen van toepassing op onze online activiteiten en is geldig voor bezoekers van onze website met betrekking tot de informatie die zij hebben gedeeld en/of verzameld in Checkda. Dit beleid is niet van toepassing op informatie die offline of via andere kanalen dan deze website wordt verzameld."])},
    "subtitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestemming"])},
    "part4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door onze website of apps te gebruiken, stemt u in met ons privacybeleid en gaat u akkoord met de voorwaarden ervan."])},
    "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie die we verzamelen"])},
    "part5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De persoonlijke informatie die u wordt gevraagd te verstrekken, en de redenen waarom u wordt gevraagd om deze te verstrekken, zullen u duidelijk worden gemaakt op het moment dat we u vragen om uw persoonlijke informatie te verstrekken."])},
    "part6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u rechtstreeks contact met ons opneemt, kunnen we aanvullende informatie over u ontvangen, zoals uw naam, e-mailadres, telefoonnummer, de inhoud van het bericht en/of bijlagen die u ons kunt sturen, en alle andere informatie die u mogelijk wilt verstrekken."])},
    "part7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wanneer u zich registreert voor een Account, kunnen we om uw contactgegevens vragen, inclusief items zoals naam, bedrijfsnaam, adres, e-mailadres en telefoonnummer."])},
    "subtitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe wij uw informatie gebruiken"])},
    "part8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We gebruiken de informatie die we verzamelen op verschillende manieren, waaronder om:"])},
    "subtitle4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logboek bestanden"])},
    "part9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We volgen een standaardprocedure voor het gebruik van logbestanden. Deze bestanden registreren bezoekers wanneer ze websites bezoeken. Alle hostingbedrijven doen dit en een deel van de analyses van hostingdiensten. De informatie die door logbestanden wordt verzameld, omvat IP-adressen (Internet Protocol), browsertype, Internet Service Provider (ISP), datum- en tijdstempel, verwijzings-/afsluitpagina's en mogelijk het aantal klikken. Deze zijn niet gekoppeld aan informatie die persoonlijk identificeerbaar is. Het doel van de informatie is om trends te analyseren, de site te beheren, de bewegingen van gebruikers op de website te volgen en demografische informatie te verzamelen."])},
    "subtitle5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVG-gegevensbeschermingsrechten"])},
    "part10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We willen ervoor zorgen dat u volledig op de hoogte bent van al uw gegevensbeschermingsrechten. Elke gebruiker heeft recht op het volgende:"])},
    "list1": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze website aanbieden, beheren en onderhouden"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onze website verbeteren, personaliseren en uitbreiden"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begrijpen en analyseren hoe u onze website gebruikt"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe producten, services, functies en functionaliteit ontwikkelen"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Met u communiceren, rechtstreeks of via een van onze partners, ook voor klantenservice, om u updates en andere informatie met betrekking tot de website te verstrekken, en voor marketing- en promotiedoeleinden"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je emails sturen"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vind en voorkom fraude"])}
    },
    "list2": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recht op inzage – U heeft het recht om kopieën van uw persoonsgegevens op te vragen. Voor deze service kunnen wij u een kleine vergoeding vragen."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recht op verbetering – U hebt het recht om te verzoeken dat we alle informatie corrigeren waarvan u denkt dat deze onjuist is. U hebt ook het recht om ons te verzoeken de informatie aan te vullen waarvan u denkt dat deze onvolledig is."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recht om vergeten te worden – U heeft het recht om onder bepaalde voorwaarden te verzoeken dat wij uw persoonsgegevens wissen."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recht op de beperking van verwerking – U heeft het recht om onder bepaalde voorwaarden te verzoeken dat wij de verwerking van uw persoonsgegevens beperken."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het recht om bezwaar te maken tegen verwerking – U heeft het recht om onder bepaalde voorwaarden bezwaar te maken tegen onze verwerking van uw persoonsgegevens."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recht op overdraagbaarheid van persoonsgegevens – U heeft het recht om onder bepaalde voorwaarden te verzoeken dat wij de door ons verzamelde gegevens overdragen aan een andere organisatie, of rechtstreeks aan u."])}
    },
    "part11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u een verzoek doet, hebben we een maand om op u te reageren. Als u een van deze rechten wilt uitoefenen, neem dan contact met ons op."])}
  },
  "forms": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzenden"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulier resetten"])}
  },
  "navbar": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "student+parents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerlingen en ouders"])},
    "schools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scholen"])},
    "apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmelden"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])}
  },
  "languages": {
    "nl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NL"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])}
  }
}